export default {
    methods: {
        getEmploymentType: function(data, callback){
            this.$store.dispatch('getEmploymentType', data)
            .then((rs)=>{
                callback && callback(rs, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        },
        getQualification: function(data, callback){
            this.$store.dispatch('getQualification', data)
            .then((rs)=>{
                callback && callback(rs, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        },
        getWorkYears: function(data, callback){
            this.$store.dispatch('getWorkYears', data)
            .then((rs)=>{
                callback && callback(rs, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        },
        loadData: function(data, callback){
            this.$store.dispatch('getJobDetail', data)
            .then((rs)=>{
                callback && callback(rs, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        }
    }
}